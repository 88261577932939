<template>
    <el-dialog :title="title" :close-on-click-modal="false" :fullscreen="true" :visible.sync="visible"
               class="collectForm">
        <div v-loading="loading" class="formBox">
            <el-form size="small" :inline="true" :model="inputForm" :rules="dataRule" ref="inputForm"
                     label-width="160px" :disabled="method==='view'" class="recordReception">
                <el-descriptions title="移交信息"></el-descriptions>
                <el-form-item prop="transferUnitName" label="移交单位：">
                    <el-input v-model.trim="inputForm.transferUnitName" disabled></el-input>
                </el-form-item>
                <el-form-item prop="acceptUnit" label="接收单位：">
                    <el-input v-model.trim="inputForm.acceptUnit" maxlength="50" placeholder="请输入接收单位（50字内）"
                              clearable></el-input>
                </el-form-item>
                <el-form-item prop="transferDepartmentName" label="移交单位部门：">
                    <el-input v-model.trim="inputForm.transferDepartmentName" disabled></el-input>
                </el-form-item>
                <el-form-item prop="acceptDepartment" label="接收单位部门：">
                    <el-input v-model.trim="inputForm.acceptDepartment" maxlength="50" placeholder="请输入接收单位部门（50字内）"
                              clearable></el-input>
                </el-form-item>
                <el-form-item prop="transferUserName" label="移交人员：">
                    <el-input v-model.trim="inputForm.transferUserName" disabled></el-input>
                </el-form-item>
                <el-form-item prop="acceptUser" label="接收人员：">
                    <el-input v-model.trim="inputForm.acceptUser" maxlength="20" placeholder="请输入接收人员（20字内）"
                              clearable></el-input>
                </el-form-item>
                <el-form-item prop="transferTime" label="移交时间：">
                    <el-date-picker
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            v-model="inputForm.transferTime"
                            type="datetime"
                            placeholder="请选择移交时间" class="w100i">
                    </el-date-picker>
                </el-form-item>
                <el-form-item prop="acceptTime" label="接收时间：">
                    <el-date-picker
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            v-model="inputForm.acceptTime"
                            type="datetime"
                            placeholder="请选择接收时间" class="w100i">
                    </el-date-picker>
                </el-form-item>
                <el-form-item prop="inspect" label="是否移交数据检查：">
                    <el-radio-group v-model="inputForm.inspect">
                        <el-radio label="1">是</el-radio>
                        <el-radio label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="enterMuseum" label="是否移交进馆：">
                    <el-radio-group v-model="inputForm.enterMuseum">
                        <el-radio label="1">是</el-radio>
                        <el-radio label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注：" prop="remark" class="rowBox">
                    <el-input
                            maxlength="200"
                            type="textarea"
                            :rows="2"
                            placeholder="请输入备注信息。（限200字）"
                            v-model="inputForm.remark">
                    </el-input>
                </el-form-item>
                <el-descriptions title="移交档案"></el-descriptions>
                <el-form-item label="档案：" prop="recordList" class="rowBox">
                    <el-button size="small" type="primary" @click="selRecord()" v-if="method != 'view'">请选择</el-button>
                    <el-table :data="inputForm.recordList" size="small" max-height="300" class="table m_t1"
                              v-if="inputForm.recordList.length">
                        <el-table-column prop="name" label="借阅卷宗" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div>{{ scope.row.name}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fileNumber" label="附件数量" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="fileSize" label="附件大小（MB）" show-overflow-tooltip></el-table-column>
                        <el-table-column fixed="right" width="180" label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="view(scope.row, 'view')" :disabled="false">
                                    详情
                                </el-button>
                                <el-button type="text" size="small" v-if="method == 'add'"
                                           @click="del(scope.$index)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
        </div>
        <div class="text_center p_t2">
            <el-button size="small" @click="visible = false">{{method == 'view' ? '关闭' : '取消'}}</el-button>
            <el-button size="small" type="primary" v-if="method != 'view'" @click="doSubmit()"
                       v-noMoreClick>保存
            </el-button>
        </div>
        <SelectRecordList ref="selectRecordList" @getDataList="getRecordList"></SelectRecordList>
        <RecordDetails ref="recordDetails"></RecordDetails>
    </el-dialog>
</template>

<script>
    import SelectRecordList from '../borrow/components/selectRecordList'
    import RecordDetails from '../borrow/components/recordDetails'

    export default {
        components: {SelectRecordList, RecordDetails},
        data() {
            var validateTransferTime = (rule, value, callback) => {
                let {acceptTime} = this.inputForm
                if (new Date(value) < new Date()) {
                    callback(new Error('移交时间必须大于或等于当前时间'));
                    return
                }
                if (acceptTime && new Date(value) > new Date(acceptTime)) {
                    callback(new Error('移交时间必须小于或等于接收时间'));
                    return
                }
                if (new Date(acceptTime) > new Date() && new Date(acceptTime) >= new Date(value)) {
                    this.$refs['inputForm'].clearValidate(['acceptTime']);
                }
                callback();
            };
            var validateAcceptTime = (rule, value, callback) => {
                let {transferTime} = this.inputForm
                if (new Date(value) < new Date()) {
                    callback(new Error('接收时间必须大于或等于当前时间'));
                    return
                }
                if (transferTime && new Date(value) < new Date(transferTime)) {
                    callback(new Error('接收时间必须大于或等于移交时间'));
                    return
                }
                if (new Date(transferTime) > new Date() && new Date(transferTime) <= new Date(value)) {
                    this.$refs['inputForm'].clearValidate(['transferTime']);
                }
                callback();
            };
            return {
                title: '',
                visible: false,
                loading: false,
                method: '',
                inputForm: {
                    id: '',
                    transferUnit: '',
                    transferUnitName: '',
                    acceptUnit: '',
                    transferDepartment: '',
                    transferDepartmentName: '',
                    acceptDepartment: '',
                    transferUser: '',
                    transferUserName: '',
                    acceptUser: '',
                    transferTime: '',
                    acceptTime: '',
                    inspect: '',
                    enterMuseum: '',
                    remark: '',
                    recordList: [],
                },
                transferUnitOpt: [],
                transferDepartmentOpt: [],
                transferUserOpt: [],
                dataRule: {
                    transferUnitName: [
                        {required: true, message: '移交单位不能为空', trigger: 'blur'},
                    ],
                    acceptUnit: [
                        {required: true, message: '接收单位不能为空', trigger: 'blur'},
                    ],
                    transferDepartmentName: [
                        {required: true, message: '移交单位部门不能为空', trigger: 'change'},
                    ],
                    acceptDepartment: [
                        {required: true, message: '接收单位部门不能为空', trigger: 'blur'},
                    ],
                    transferUserName: [
                        {required: true, message: '移交人员不能为空', trigger: 'change'},
                    ],
                    acceptUser: [
                        {required: true, message: '接收人员不能为空', trigger: 'blur'},
                    ],
                    transferTime: [
                        {required: true, message: '移交时间不能为空', trigger: 'change'},
                        {validator: validateTransferTime, trigger: 'blur'}
                    ],
                    acceptTime: [
                        {required: true, message: '接收时间不能为空', trigger: 'change'},
                        {validator: validateAcceptTime, trigger: 'blur'}
                    ],
                    inspect: [
                        {required: true, message: '请选择是否移交数据检查', trigger: 'change'},
                    ],
                    enterMuseum: [
                        {required: true, message: '请选择是否移交进馆', trigger: 'change'},
                    ],
                    recordList: [
                        {required: true, message: '档案不能为空', trigger: 'change'}
                    ]
                },
            }
        },
        methods: {
            init(row, method) {
                this.loading = false
                if (row) {
                    this.inputForm.id = row.id
                } else {
                    this.inputForm.id = ''
                }
                this.method = method
                if (method === 'add') {
                    this.title = '新增移交'
                } else if (method === 'edit') {
                    this.title = '修改移交'
                } else if (method === 'view') {
                    this.title = '查看移交'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method !== 'add') {
                        this.getInfo()
                    } else {
                        this.inputForm.transferUnitName = this.$store.state.user.userInfo.libraryName
                        this.inputForm.transferUnit = this.$store.state.user.userInfo.libraryId
                        this.inputForm.transferDepartmentName = this.$store.state.user.userInfo.departmentName
                        this.inputForm.transferDepartment = this.$store.state.user.userInfo.department
                        this.inputForm.transferUserName = this.$store.state.user.userInfo.name
                        this.inputForm.transferUser = this.$store.state.user.userInfo.id
                    }
                })
            },
            // 获取详情
            getInfo() {
                this.$axios(this.api.record.getArchivesTransferById + '/' + this.inputForm.id).then((res) => {
                    if (res.status) {
                        this.inputForm = this.recover(this.inputForm, res.data.archivesTransferDepVO)
                        this.inputForm.recordList = res.data.list
                    }
                })
            },
            // 选择档案
            selRecord() {
                this.$refs.selectRecordList.init(this.inputForm.recordList, 4, 100)
            },
            // 获取选择的档案数据
            getRecordList(data) {
                let ids = []
                data.map(item => {
                    ids.push(item.id)
                })
                this.inputForm.recordList.map(item => {
                    ids.push(item.archivesDataId)
                })
                this.findFileSize(ids)
                if (this.inputForm.recordList.length) {
                    this.$refs['inputForm'].clearValidate(['recordList']);
                }

            },
            // 获取档案附件数量和大小
            findFileSize(ids) {
                this.loading = true
                this.$axios(this.api.record.findFileSize, ids, 'post').then((res) => {
                    if (res.status) {
                        this.inputForm.recordList = res.data
                        this.loading = false
                    }
                })
            },
            // 查看
            view(row, method) {
                if (row.archivesDelFag == 1) {
                    this.$message.error('该档案已删除！')
                    return
                }
                this.$refs.recordDetails.init(row, method, row.archivesBasicDataId, 'archivesDataId')
            },
            // 删除档案
            del(index) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.inputForm.recordList.splice(index, 1);
                    this.$message.success('删除成功')
                })
            },
            // 确定
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let api = this.api.record.archivestransferAdd,
                            type = 'post'
                        if (this.method == 'edit') {
                            api = this.api.record.updateArchivesTransferById
                            type = 'put'
                        }
                        let p = JSON.parse(JSON.stringify(this.inputForm))
                        delete p.recordList
                        let ids = []
                        this.inputForm.recordList.map(item => {
                            ids.push(item.archivesDataId)
                        })
                        this.$axios(api, {
                            archivesTransfer: p,
                            ids: ids,
                        }, type).then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success('操作成功')
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(data.msg)
                            }
                        })
                    }
                })
            },
        },
    }
</script>

<style scoped>

</style>
